<template>
  <div>
    <el-breadcrumb class="ml-30" separator="/">
      <el-breadcrumb-item>消息通知</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flexCol alignItems">
      <div class="content">
        <div class="top-search">
          <div class="flexRow">
            <el-button type="primary" @click="del">删除消息</el-button>
            <el-button @click="sign" style="margin-left:20px;">标记未读</el-button>
          </div>
        </div>
        <el-table :data="contractList" border style="width: 100%" :header-cell-style="this.utils.getRowClass">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="index" label="序号" width="100px"></el-table-column>
          <el-table-column prop="acreage" label="消息内容"></el-table-column>
          <el-table-column prop="shelfArea" label="消息通知"></el-table-column>
        </el-table>
        <div class="flexRow justifyContentSpac" style="margin-top:20px">
          <div></div>
          <el-pagination
              @size-change="handleSizeChange"
              background
              layout="total, prev, pager, next,sizes"
              :total="total"
              :page-sizes="[10, 15, 20, 30, 50, 100]"
              :page-size="pageSize"
              @current-change="changePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        storeId: sessionStorage.getItem("storeId"),
      },
      contractList: [],
      total: 0,
      page: 1,
      pageSize: 10,
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {

    changePage(e) {
      this.page = e;
      this.getData();
    },
    handleSizeChange(e) {
      this.page = 1;
      this.pageSize = e;
      this.getData();
    },
    del() {

    },
    sign() {

    },
    getData() {
      this.$api
          .getStoreShop({
            page: this.page,
            pageSize: this.pageSize,
            search: this.search,
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.total = res.data.data.total;
              this.contractList = res.data.data.records;
            }
          });
    },
  },
};
</script>

<style scoped lang="scss">
.caozuo {
  color: rgb(138, 43, 226);
  margin-right: 20px;
  cursor: pointer;
}

.contennt-desc {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.alignItems {
  align-items: center;
}

.justifyContentSpac {
  justify-content: space-between;
}

.justifyContentCenter {
  justify-content: center;
}

.top-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .search-title {
    font-size: 14px;
    margin-left: 20px;
  }

  .el-input {
    width: 200px;
  }

  .el-select {
    width: 200px;
  }
}

.content {
  width: 95%;
  background: #fff;
  padding: 30px;
}
.ml-30 {
  margin: 20px 40px;
}
</style>
